import * as React from 'react'
import Layout from '../../layout'
import BirthdatePage from '../../pagesComponents/login/birthdate'

const Birthdate = () => {
	return (
		<Layout title="Enter your birthday">
			<BirthdatePage />
		</Layout>
	)
}

export default Birthdate
