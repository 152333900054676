import React, { useContext } from 'react'
import BasePage from '../../components/basePage'
import { Button, Flex, Input as InputChakra, Text } from '@chakra-ui/react'
import { navigate } from 'gatsby'
import { getTempProfile, setTempProfile } from '../../helpers/database'
import moment from 'moment'
import { yupResolver } from '@hookform/resolvers/yup'
import { date, object } from 'yup'
import { useForm } from 'react-hook-form'
import CalendarSVG from '../../icons/calendar.svg'
import ClearSVG from '../../icons/clear.svg'
import DatePicker from 'react-date-picker'
import { useAuth } from '../../hooks/useAuth'
import eventTracking from '../../services/eventTracking'

const BirthdatePage = () => {
	const { birth } = getTempProfile() || {}
	const { user } = useAuth()
	const resolver = yupResolver(
		object()
			.shape({
				birth: date()
					.nullable()
					.notRequired()
					.max(new Date())
					.transform((curr, orig) => (orig === '' ? null : curr))
					.typeError('Invalid Date')
			})
			.required()
	)
	const {
		watch,
		handleSubmit,
		setValue,
		getValues,
		formState: { errors }
	} = useForm({
		resolver,
		mode: 'onSubmit',
		reValidateMode: 'onChange',
		shouldUseNativeValidation: false,
		defaultValues: {
			birth: birth ? moment(birth).format('YYYY-MM-DD') : null
		}
	})
	const watchBirth = watch('birth')

	const onSubmit = async () => {
		const data = getValues()
		const tempProfile = getTempProfile()
		tempProfile.birth = !!data.birth
			? moment(data.birth).format('YYYY-MM-DD')
			: null
		setTempProfile(tempProfile)

		eventTracking('DOB_input', {
			DOB_input: data?.birth,
			email: user?.email
		})
		await navigate('/login/terms/')
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<BasePage
				theme="dark"
				access={1}
				parent="login"
				backBtn={-1}
				largeTitle={
					<>
						When’s your
						<br />
						Birthday?
					</>
				}
				secondaryBtn={
					<Button
						variant="secondary"
						mt="10px"
						onClick={() => navigate('/login/terms/')}
					>
						Skip
					</Button>
				}
				primaryBtn={
					<Button variant="primary" type="submit">
						Continue
					</Button>
				}
			>
				<Flex position="relative">
					<Flex flex="1" h="60px" bg="darkGray" mt="40px">
						<Flex
							cursor="pointer"
							alignItems="center"
							position="absolute"
							top="40px"
							bottom="0"
							left="0"
							right="0"
							px="22px"
						>
							<Text
								variant={!!watchBirth ? 'date' : 'placeholder'}
								zIndex="2"
								pointerEvents="none"
							>
								{!!watchBirth
									? moment(watchBirth).format('DD/MM/YYYY')
									: 'Date of Birth (Optional)'}
							</Text>
						</Flex>
						<DatePicker
							value={watchBirth}
							clearIcon={!!watchBirth ? <ClearSVG /> : null}
							calendarIcon={
								<CalendarSVG style={{ marginRight: 15 }} />
							}
							onChange={(value) => setValue('birth', value)}
						/>
					</Flex>
				</Flex>
				{!!errors?.birth && (
					<Text variant="error">{errors.birth.message}</Text>
				)}
				<Text variant="m" color="white" textAlign="center" mt="10px">
					Who doesn’t love a nice surprise? We use your date of birth
					to provide offers and discounts for birthdays.
				</Text>
			</BasePage>
		</form>
	)
}

export default BirthdatePage
